// LoadingOverlay.js
import React from 'react';
import { useLoading } from './LoadingContext';
import { Loader } from 'semantic-ui-react'

const LoadingOverlay = () => {
    const { isLoading } = useLoading();

    if (!isLoading) return null;

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            color: 'white',
            fontSize: '1.5em',
        }}>
            <Loader active inline='centered' >Loading...</Loader>
        </div>
    );
};

export default LoadingOverlay;
