import React, {Component} from 'react'
import { Card, Image } from 'semantic-ui-react'
import * as jwtDecode from "jwt-decode";
import axios from 'axios';

import * as settings from '../settings';

class CardGroup extends Component {
    constructor(props) {
        super(props);

        this.attributes = props.attributes;
        this.goToPrePopulatedMaterials = props.goToPrePopulatedMaterials;

        this.accessToken = localStorage.getItem('vcr-accessToken');

        this.state = {
            expiredEvidences: [],
            substancesEvidences: [],
            needsApprovalEvidences: [],
            notCompliantEvidences: []
        }
    };

    componentDidMount() {
        this.getMaterialsList();
    }

    getMaterialsList = () => {
        var self = this;
        axios.get(settings.backend_url + '/materials',
            {
                headers: {
                    Authorization: self.accessToken
                }
            })
            .then(res => {
                const materialsList = res.data.data.Items;
                if(materialsList && materialsList.length > 0) {
                    var sessionIdInfo = jwtDecode(self.accessToken);
                    let adminUser = false;
                    for(let i = 0; i < sessionIdInfo['cognito:groups'].length; i++) {
                        if(sessionIdInfo['cognito:groups'][i] === 'vcr-admin') {
                            adminUser = true;
                            break;
                        }
                    }
                    if(!adminUser) {
                        let factory = JSON.parse(localStorage.getItem('vcr-factory'));
                        if(factory) {
                            let pdivName = factory.key;
                            if(pdivName) {
                                for(let i = 0; i < materialsList.length; i++) {
                                    if(materialsList[i].pdiv !== pdivName) {
                                        materialsList.splice(i, 1);
                                        i--;
                                    }
                                }
                            } else {
                                materialsList.splice(0, materialsList.length);
                            }
                        }
                    }
                    

                    // Get evidences for each material
                    for(let i = 0; i < materialsList.length; i++) {
                        axios.get(settings.backend_url + '/evidence/' + materialsList[i].id)
                            .then(res => {
                                if(res.data.data != null) {
                                    const evidence = res.data.data;
                                    materialsList[i]['evidenceType'] = evidence.evidenceType;
                                    materialsList[i]['evidenceStatus'] = evidence.evidenceStatus;

                                    let date1 = new Date();
                                    let date2 = new Date(evidence.dateRequired);

                                    date1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
                                    date2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
                                    if(date1 > date2)
                                    {
                                        let expiredEvidences = this.state.expiredEvidences;
                                        expiredEvidences.push(materialsList[i]);
                                        this.setState({expiredEvidences: expiredEvidences});
                                    }

                                    if(evidence.dateReceived.length === 1)
                                    {
                                        let expiredEvidences = this.state.expiredEvidences;
                                        expiredEvidences.push(materialsList[i]);
                                        this.setState({expiredEvidences: expiredEvidences});
                                    }
                                    
                                    if(evidence.declarable === 'Declarable substances present' || ((evidence.reach === 'Compliant' || evidence.reach === true) && evidence.reachNotes.length > 1))
                                    {
                                        let substancesEvidences = this.state.substancesEvidences;
                                        substancesEvidences.push(materialsList[i]);
                                        this.setState({substancesEvidences: substancesEvidences});
                                    }

                                    if(evidence.dateReceived.length > 1 && evidence.approver.length < 2)
                                    {
                                        let needsApprovalEvidences = this.state.needsApprovalEvidences;
                                        needsApprovalEvidences.push(materialsList[i]);
                                        this.setState({needsApprovalEvidences: needsApprovalEvidences});
                                    }

                                    if(evidence.prohibited === 'Prohibited substances present' || (evidence.rohs !== 'Compliant' && evidence.rohs !== 'Pending' && evidence.rohs !== true) || (evidence.reach !== 'Compliant' && evidence.reach !== 'Pending' && evidence.reach !== true))
                                    {
                                        let notCompliantEvidences = this.state.notCompliantEvidences;
                                        notCompliantEvidences.push(materialsList[i]);
                                        this.setState({notCompliantEvidences: notCompliantEvidences});
                                    }
                                } else {
                                    let expiredEvidences = this.state.expiredEvidences;
                                    expiredEvidences.push(materialsList[i]);
                                    this.setState({expiredEvidences: expiredEvidences});
                                }
                            })
                            .catch(err => {
                                console.error(err);
                            });
                    }
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    render() {
        return (
            <Card.Group itemsPerRow={4}>
                <Card onClick={(e) => this.goToPrePopulatedMaterials(this.state.expiredEvidences)}>
                    <Card.Content>
                        <Image
                        size='tiny'
                        src='/images/email_refresh.png'
                        />
                        <Card.Header style={{paddingTop: '1rem'}}>{this.state.expiredEvidences.length}</Card.Header>
                        <Card.Description>
                        Evidences missing or expired
                        </Card.Description>
                    </Card.Content>
                </Card>
                <Card onClick={(e) => this.goToPrePopulatedMaterials(this.state.substancesEvidences)}>
                    <Card.Content>
                        <Image
                        size='tiny'
                        src='/images/science_chemistry.png'
                        />
                        <Card.Header style={{paddingTop: '1rem'}}>{this.state.substancesEvidences.length}</Card.Header>
                        <Card.Description>
                        Materials containing SVHCs or declarable substances
                        </Card.Description>
                    </Card.Content>
                </Card>
                <Card onClick={(e) => this.goToPrePopulatedMaterials(this.state.needsApprovalEvidences)}>
                    <Card.Content>
                        <Image
                        size='tiny'
                        src='/images/email_warning.png'
                        />
                        <Card.Header style={{paddingTop: '1rem'}}>{this.state.needsApprovalEvidences.length}</Card.Header>
                        <Card.Description>
                        Evidences need approval
                        </Card.Description>
                    </Card.Content>
                </Card>
                <Card onClick={(e) => this.goToPrePopulatedMaterials(this.state.notCompliantEvidences)}>
                    <Card.Content>
                        <Image
                        size='tiny'
                        src='/images/fish_dead.png'
                        />
                        <Card.Header style={{paddingTop: '1rem'}}>{this.state.notCompliantEvidences.length}</Card.Header>
                        <Card.Description>
                        Materials that are not compliant
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Card.Group>
        );
    }
}

export default CardGroup
