import React, { Component } from 'react';

import * as jwtDecode from "jwt-decode";
import { Button, Divider, Form, Grid, Header } from 'semantic-ui-react';
import {
    AuthenticationDetails,
    CognitoUserPool,
    CognitoUser
} from "amazon-cognito-identity-js";

import * as settings from '../settings';
import ResetPassword from './ResetPassword';
import _ from 'lodash';

class Login extends Component {
    constructor(props) {
        super(props);
        if (window.performance) {
            if (performance.navigation.type !== 1 || !localStorage.getItem('vcr-accessToken')) {
                localStorage.removeItem('vcr-accessToken');
                localStorage.removeItem('vcr-idToken');
                localStorage.removeItem('vcr-factory');
                localStorage.removeItem('vcr-factories');
                this.view = <Login setFactory={this.setFactory} goToDashBoard={this.goToDashBoard} setFactories={this.setFactories} />;
            } else {
                props.goToDashBoard();
            }
        }

        this.state = {
            setFactories: props.setFactories,
            setFactory: props.setFactory,
            goToDashBoard: props.goToDashBoard,
            authenticationData: {
                Username: '',
                Password: '',
                NewPassword: ''
            },
            cognitoUser: null,
            sessionUserAttributes: null,
            invalidLoginInput: true,
            invalidNewPasswordInput: true,
            errorLabel: null,
            header: "LOGIN"
        };

        this.state['view'] = this.renderLoginForm();
        this.handler = this.handler.bind(this)
    };

    handler = (value) => {
        this.setState({
            header: value
        })
    }

    handleLoginInputChange = (event, { name, value }) => {
        let authenticationData = this.state.authenticationData;
        authenticationData[this.ucfirst(name)] = value;

        if (authenticationData.Username.length > 0 && authenticationData.Password.length > 0) {
            this.setState({
                authenticationData: authenticationData,
                invalidLoginInput: false
            }, function () {
                this.setState({ view: this.renderLoginForm() });
            });
        } else {
            this.setState({
                authenticationData: authenticationData,
                invalidLoginInput: true
            }, function () {
                this.setState({ view: this.renderLoginForm() });
            });
        }
    };

    handleNewPasswordChange = (event, { name, value }) => {
        let authenticationData = this.state.authenticationData;
        authenticationData[this.ucfirst(name)] = value;

        if (authenticationData.NewPassword.length > 0) {
            this.setState({
                authenticationData: authenticationData,
                invalidNewPasswordInput: false
            }, function () {
                this.setState({ view: this.renderNewPasswordForm() });
            });
        } else {
            this.setState({
                authenticationData: authenticationData,
                invalidNewPasswordInput: true
            }, function () {
                this.setState({ view: this.renderNewPasswordForm() });
            });
        }
    };

    ucfirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    performLogin = () => {
        this.setState({ errorLabel: null });
        const authenticationDetails = new AuthenticationDetails(this.state.authenticationData);
        const userPool = new CognitoUserPool(settings.login_pool_data);


        var userData = {
            Username: this.state.authenticationData.Username.toLowerCase(),
            Pool: userPool
        };
        var self = this;
        self.setState({ cognitoUser: new CognitoUser(userData) }, function () {
            self.state.cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function (result) {
                    var accessToken = result.getAccessToken().getJwtToken();
                    localStorage.setItem('vcr-accessToken', accessToken);

                    var sessionIdInfo = jwtDecode(accessToken);
                    var factories = _.filter(sessionIdInfo['cognito:groups'], function(group){
                        return group.startsWith('pdiv-')
                    });
                    self.state.setFactories(factories);

                    /* Use the idToken for Logins Map when Federating User Pools with identity pools or when passing through an Authorization Header to an API Gateway Authorizer*/
                    var idToken = result.idToken.jwtToken;
                    localStorage.setItem('vcr-idToken', idToken);
                    self.state.goToDashBoard();
                },

                onFailure: function (err) {
                    self.setState({ errorLabel: React.createElement('Label', { style: { color: 'red', fontWeight: 'bold' } }, err.message) });
                },

                newPasswordRequired: function (userAttributes, requiredAttributes) {
                    // User was signed up by an admin and must provide new
                    // password and required attributes, if any, to complete
                    // authentication.

                    // the api doesn't accept this field back
                    delete userAttributes.email_verified;

                    // store userAttributes on global variable
                    self.setState({ sessionUserAttributes: userAttributes, view: self.renderNewPasswordForm() });
                }
            });
        });
    }

    onSuccess = (result) => {
        var accessToken = result.getAccessToken().getJwtToken();
        localStorage.setItem('vcr-accessToken', accessToken);

        var sessionIdInfo = jwtDecode(accessToken);
        var factories = _.filter(sessionIdInfo['cognito:groups'], function(group){
            return group.startsWith('pdiv-')
        });
        this.state.setFactories(factories);

        /* Use the idToken for Logins Map when Federating User Pools with identity pools or when passing through an Authorization Header to an API Gateway Authorizer*/
        var idToken = result.idToken.jwtToken;
        localStorage.setItem('vcr-idToken', idToken);
        this.state.goToDashBoard();
    }

    onFailure = (err) => {
        this.setState({ errorLabel: React.createElement('label', { style: { color: 'red', fontWeight: 'bold' } }, err.message) });
    }

    updatePassword = () => {
        this.state.cognitoUser.completeNewPasswordChallenge(this.state.authenticationData.NewPassword, this.state.sessionUserAttributes, this);
        let authenticationData = this.state.authenticationData;
        authenticationData.Username = '';
        authenticationData.Password = '';
        this.setState({ authenticationData: authenticationData, view: this.renderLoginForm() });
    }

    renderLoginForm() {
        return (
            <div>
                <Form>
                    <Form.Field>
                        <Form.Input fluid required name="username" placeholder='Username' onChange={this.handleLoginInputChange} value={this.state.authenticationData.Username} />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input fluid required type="password" name="password" placeholder='Password' onChange={this.handleLoginInputChange} value={this.state.authenticationData.Password} />
                    </Form.Field>
                    <Button type='submit' onClick={this.performLogin} disabled={this.state.invalidLoginInput}>Login</Button>
                </Form>
                <div>
                    <a href="" onClick={(e) => this.resetPassword(e)}>Forgot password?</a>
                </div>
            </div>
        );
    }

    resetPassword(e) {
        e.preventDefault();
        this.setState({ header: "RESET PASSWORD - STEP 1" })
        return this.setState({ view: <ResetPassword handler={this.handler} /> })
    }

    renderNewPasswordForm() {
        return (
            <div>
                <Form>
                    <Form.Field>
                        <Form.Input fluid required id="newPassword" type="password" name="newPassword" placeholder='New password' onChange={this.handleNewPasswordChange} value={this.state.authenticationData.NewPassword} />
                    </Form.Field>
                    <Button type='submit' onClick={this.updatePassword} disabled={this.state.invalidNewPasswordInput}>Submit</Button>
                </Form>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Header as='h1'>{this.state.header}</Header>
                <Divider section hidden />
                <Grid textAlign='center' verticalAlign='middle'>
                    <Grid.Column style={{ maxWidth: 450 }}>
                        {this.state.errorLabel}
                        <Divider section hidden />
                        {this.state.view}
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

export default Login;
