import React, {Component} from 'react';
import MaterialHeader from './MaterialHeader';
import MaterialBody from './MaterialBody';
import { Header } from 'semantic-ui-react';

class Material extends Component {   
    constructor(props) {
        super(props);

        this.readOnly = props.readOnly;
        this.attributes = props.attributes;
        
        let material = props.material;
        if(!material.notes) {
            material['notes'] = 'Additional material notes';
        }

        this.state = {
            material: material,
            changeView: props.changeView,
            updateMaterial: props.updateMaterial
        };
    };

    render() {
        const { showLoading, hideLoading } = this.props;
        return (
            <div>
                <Header as='h1' className="cursor-pointer" onClick={() => this.state.changeView(null)}>
                    <img style={{width: '0.9rem', verticalAlign: 'inherit'}}
                            src="images/arrow-left.png" alt='left arrow'/>
                    BACK TO MATERIALS</Header>
                <MaterialHeader readOnly={this.readOnly} attributes={this.attributes} materialInfo={this.state.material} updateMaterial={this.state.updateMaterial}/>
                <MaterialBody readOnly={this.readOnly} attributes={this.attributes} materialInfo={this.state.material} updateMaterial={this.state.updateMaterial}
                    showLoading={showLoading} hideLoading={hideLoading}/>
            </div>
        );
    }
}

export default Material; 
