import React, { Component } from 'react';
import { Button, Form, Header, Modal } from 'semantic-ui-react';
import axios from 'axios';

import * as settings from '../settings';

class CreateVendorModal extends Component {

    constructor(props) {
        super(props);

        this.admin = props.admin;
        this.factory = props.factory;
        this.attributes = props.attributes;
        this.refreshVendors = props.refreshVendors;

        this.pdivOptions = []
        this.initialState = {
            vendorName: '',
            vendorLocation: '',
            description: '',
            pdiv: null,
            internalVendor: 'No',
            notes: '',
            nameError: false,
            locationError: false,
            descriptionError: false,
            notesError: false
        };
        this.state = this.initialState;
    }

    componentDidMount() {
      if(!this.admin) {
        // set pdivOptions by factories from local storage
        let factories = JSON.parse(localStorage.getItem('vcr-factories'));
        for(let i = 0; i < factories.length; i++) {
          this.pdivOptions.push({
            key: factories[i].text,
            text: factories[i].text,
            value: factories[i].text
          })
        }
      } else {
        axios.get(settings.backend_url + '/attributes')
        .then(res => {
          const attributes = res.data.data;
          for(let i = 0; i < attributes.pdiv.length; i++) {
            this.pdivOptions.push({
              key: attributes.pdiv[i].name,
              text: attributes.pdiv[i].name,
              value: attributes.pdiv[i].name
            })
          }
        })
        .catch(err => {
            console.error(err);
        });
      }
    }

    missingRequired = () => {
        return (
            !this.state.vendorName || !this.state.vendorLocation || !this.state.description
            || !this.state.pdiv || !this.state.internalVendor
        );
    };

    validateContent = (data, error) => {
        const jsonRegExp = /\{.*:.*\}/g;
        const wordRegExp = /(?:^|\W)ALL_ATTRIBUTES|AttributeValueList|ComparisonOperator(?:$|\W)/g;
        if (data.match(jsonRegExp) || data.match(wordRegExp)) {
            this.setState({ [error]: 'Invalid input' });
            return false;
        }
        else return true;
    }

    handleSubmit = (event) => {
      var self = this;
        event.preventDefault();
        if (!this.missingRequired()) {
            if (this.validateContent(this.state.vendorName, 'nameError') &
                this.validateContent(this.state.vendorLocation, 'locationError') &
                this.validateContent(this.state.description, 'descriptionError') &
                this.validateContent(this.state.notes, 'notesError')) {
                    axios.post(settings.backend_url + '/vendors',
                      { vendorName: this.state.vendorName,
                        vendorLocation: this.state.vendorLocation,
                        description: this.state.description,
                        pdiv: this.state.pdiv,
                        internalVendor: this.state.internalVendor,
                        notes: this.state.notes
                      })
                      .then(res => {
                        self.setState({
                          vendorName: '',
                          vendorLocation: '',
                          description: '',
                          pdiv: null,
                          internalVendor: 'No'
                        });
                        self.refreshVendors();
                        self.props.closeModal();
                      })
                      .catch(err => {
                        console.error(err);
                      });
                }
        }
    };

    handleChange = (event, { name, value }) => {
        this.setState({ [name]: value });
        const error = name + 'Error';
        this.setState({ [error]: false });
    };

    render() {
        return (
          <Modal
            open={this.props.isOpen}
            onClose={this.props.closeModal}>
            <Modal.Header>CREATE VENDOR</Modal.Header>

            <Modal.Content>
                <Form>
                    <Header as='h4'>VENDOR INFORMATION</Header>
                    <Form.Group widths='equal'>
                        <Form.Input fluid style={{height: '2.7rem'}} required label='Name' name='vendorName' placeholder='Enter name'
                                    onChange={this.handleChange} error={this.state.nameError} />
                        <Form.Input fluid style={{height: '2.7rem'}} required label='Location' name='vendorLocation' placeholder='Enter location'
                                    onChange={this.handleChange} error={this.state.locationError} />
                    </Form.Group>
                    <Form.Input style={{height: '2.7rem'}} required label='Description' name='description' placeholder='Enter a description'
                                onChange={this.handleChange} error={this.state.descriptionError} />
                    <Form.Group widths='equal'>
                        <Form.Select fluid required search label='Factory' name='pdiv' options={this.pdivOptions}
                                     placeholder='Start typing or choose from list'
                                     onChange={this.handleChange} />
                        <Form.Field required>
                            <label>Internal Vendor</label>
                            {/*<Radio toggle label='Yes' />*/}
                            <Form.Group>
                                <Form.Radio label='Yes' value='Yes' name='internalVendor'
                                            checked={this.state['internalVendor'] === 'Yes'}
                                            onChange={this.handleChange} />
                                <Form.Radio label='No' value='No' name='internalVendor'
                                            checked={this.state['internalVendor'] === 'No'}
                                            onChange={this.handleChange} />
                            </Form.Group>
                        </Form.Field>
                    </Form.Group>
                    <Header as='h4'>NOTES</Header>
                    <Form.TextArea style={{ fontFamily: 'SKF-Chevin' }} label='Vendor Notes' name='notes'
                                   placeholder='Enter notes about the vendor'
                                   onChange={this.handleChange} error={this.state.notesError} />
                </Form>
            </Modal.Content>

            <Modal.Actions>
              <Button basic
                onClick={this.props.closeModal}
                content='Cancel'
              />
              <Button
                content='Create'
                type='submit'
                onClick={this.handleSubmit}
                disabled={this.missingRequired()}
              />
            </Modal.Actions>

          </Modal>
        );
    }
}

export default CreateVendorModal;