import React, {Component} from 'react';
import { Button, Checkbox, Confirm, Form, Grid, Header, Table } from 'semantic-ui-react'
import _ from 'lodash';
import * as jwtDecode from "jwt-decode";
import Material from './material/Material';
import axios from 'axios';
import Storage from './material/evidence/Storage';
import DatePicker from "react-datepicker";
import ReactExport from "react-export-excel";

import "react-datepicker/dist/react-datepicker.css";

import * as settings from '../settings';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// TODO: REFACTOR! REFACTOR! REFACTOR!
// Combine similar functions, do not keep copies of almost identical functions.
// Split in to several js files. Clean up!
class Materials extends Component {
    constructor(props) {
        super(props);
        this.refreshMaterials = props.refreshMaterials;
        this.attributes = props.attributes;
        this.factory = props.factory;
        this.vendorName = props.vendorName;
        this.materialsList = props.materialsList;

        this.admin = false;
        this.editCreate = false;
        this.approver = false;
        this.readOnly = false;

        this.accessToken = localStorage.getItem('vcr-accessToken');
        var sessionIdInfo = jwtDecode(this.accessToken);
        for(let i = 0; i < sessionIdInfo['cognito:groups'].length; i++) {
            if(sessionIdInfo['cognito:groups'][i] === 'vcr-admin') {
                this.admin = true;
            } else if(sessionIdInfo['cognito:groups'][i] === 'vcr-approver') {
                this.approver = true;
            } else if(sessionIdInfo['cognito:groups'][i] === 'vcr-edit-create') {
                this.editCreate = true;
            } else if(sessionIdInfo['cognito:groups'][i] === 'vcr-read-only') {
                this.readOnly = true;
            }
        }

        this.state = {
            getPdivOptions: props.getPdivOptions,
            materialsList: [],
            filteredList: [],
            view: null,
            sortedColumn: null,
            sortDirection: null,
            filters: null,
            filterOptions: {
                supplierOptions: [
                    {key: 'VENDOR', text: 'VENDOR', value: 'VENDOR'},
                ],
                materialCategoryOptions: [
                    {key: 'MATERIAL CATEGORY', text: 'MATERIAL CATEGORY', value: 'MATERIAL CATEGORY'},
                ],
                materialTypeOptions: [
                    {key: 'MATERIAL TYPE', text: 'MATERIAL TYPE', value: 'MATERIAL TYPE'}
                ],
                primaryMaterialOptions: [
                    {key: 'PRIMARY MATERIAL', text: 'PRIMARY MATERIAL', value: 'PRIMARY MATERIAL'}
                ],
                materialDesignationOptions: [
                    {key: 'MATERIAL DESIGNATION', text: 'MATERIAL DESIGNATION', value: 'MATERIAL DESIGNATION'}
                ],
                evidenceTypeOptions: [
                    {key: 'EVIDENCE TYPE', text: 'EVIDENCE TYPE', value: 'EVIDENCE TYPE'}
                ],
                evidenceStatusOptions: [
                    {key: 'EVIDENCE STATUS', text: 'EVIDENCE STATUS', value: 'EVIDENCE STATUS'}
                ],
                nextEvidenceRequiredOptions: [
                    {key: 'NEXT EVIDENCE REQUIRED', text: 'NEXT EVIDENCE REQUIRED', value: 'NEXT EVIDENCE REQUIRED'}
                ],
                /*materialNameOptions: [
                    {key: 'MATERIAL NAME', text: 'MATERIAL NAME', value: 'MATERIAL NAME'}
                ],*/
                metalAlloyOptions: [
                    {key: 'METAL ALLOY', text: 'METAL ALLOY', value: 'METAL ALLOY'}
                ]
            },
            selectedFilters: {supplier: null, materialCategory: null, materialType: null, primaryMaterial: null, materialDesignation: null, evidenceType: null, evidenceStatus: null, nextEvidenceRequired: null, metalAlloy: null},
            defaultFilters: {supplier: 0, materialCategory: 0, materialType: 0, primaryMaterial: 0, materialDesignation: 0, evidenceType: 0, evidenceStatus: 0, nextEvidenceRequired: 0, metalAlloy: 0},
            confirmHeader: '',
            deleteConfirmOpen: false,
            deleteData: null,
            showCheckboxes: {display: 'none'},
            editForm: null,
            showEditPanelText: 'SHOW EDIT PANEL',
            allChecked: false,
            checkboxes: {},
            selectedCheckboxes: 0,
            documents: []
        };

        this.selectedMaterials = [];

        this.multiUpdateOptions = {
            statusOptions: [],
            reachStatusOptions: [
                {key: '', text: '', value: ''},
                {key: 'Pending', text: 'Pending', value: 'Pending'},
                {key: 'Compliant', text: 'Compliant', value: 'Compliant'},
                {key: 'Not Compliant', text: 'Not Compliant', value: 'Not Compliant'}
            ],
            rohsStatusOptions: [
                {key: '', text: '', value: ''},
                {key: 'Pending', text: 'Pending', value: 'Pending'},
                {key: 'Compliant', text: 'Compliant', value: 'Compliant'},
                {key: 'Not Compliant', text: 'Not Compliant', value: 'Not Compliant'}
            ],
            declarableSubstancesStatus: [
                {key: '', text: '', value: ''},
                {key: 'Pending', text: 'Pending', value: 'Pending'},
                {key: 'No declarable substances', text: 'No declarable substances', value: 'No declarable substances'},
                {key: 'Declarable substances present', text: 'Declarable substances present', value: 'Declarable substances present'}
            ],
            prohibitedSubstancesStatus: [
                {key: '', text: '', value: ''},
                {key: 'Pending', text: 'Pending', value: 'Pending'},
                {key: 'No prohibited substances', text: 'No prohibited substances', value: 'No prohibited substances'},
                {key: 'Prohibited substances present', text: 'Prohibited substances present', value: 'Prohibited substances present'}
            ],
        };

        this.evidenceInfo = {
            evidenceType: null,
            evidenceStatus: null,
            dateRequired: null,
            dateRequested: null,
            dateReceived: null,
            dateApproved: null,
            evidenceNotes: null
        };

        this.complianceInfo = {
            reachStatus: null,
            reachNotes: null,
            rohsStatus: null,
            rohsNotes: null,
            declarableSubstancesStatus: null,
            declarableNotes: null,
            prohibitedSubstancesStatus: null,
            prohibitedNotes: null,
            complianceNotes: null
        };

        if(props.selectedFilters) {
            this.state.selectedFilters = props.selectedFilters;
        }
        if(this.vendorName) {
            let filters = this.state.selectedFilters;
            filters['supplier'] = this.vendorName;
            this.state.selectedFilters = filters;
        }

        var options;
        if(this.approver || this.admin) {
            options = [
                { key: '', text: '', value: '' },
                { key: 'Evidence required', text: 'Evidence required', value: 'Evidence required' },
                { key: 'Evidence received', text: 'Evidence received', value: 'Evidence received' },
                { key: 'Evidence approved', text: 'Evidence approved', value: 'Evidence approved' }
            ]
        } else {
            options = [
                { key: '', text: '', value: '' },
                { key: 'Evidence required', text: 'Evidence required', value: 'Evidence required' },
                { key: 'Evidence received', text: 'Evidence received', value: 'Evidence received' }
            ]
        }
        this.multiUpdateOptions.statusOptions = options;

        this.storage = new Storage();

        this.state.view = this.renderTableHeaderOnly('Loading...');
    }

    componentDidMount() {
        if(!this.admin) {
            for(let i = 0; i < this.attributes.pdivOptions.length; i++) {
                if(this.attributes.pdivOptions[i].id !== this.factory) {
                    this.attributes.pdivOptions.splice(i, 1);
                    i--;
                }
            }
        }
        if(!this.materialsList) {
            this.getMaterialsList();
        } else {
            this.setState({materialsList: this.materialsList}, function() {
                this.populateFilterOptions();
                this.applyFilters(true);
            });
        }
    }

    getMaterialsList = () => {
        axios.get(settings.backend_url + '/materials',
            {
                headers: {
                    Authorization: this.accessToken
                }
            })
            .then(res => {
                const materialsList = res.data.data.Items;
                if(!materialsList || materialsList.length < 1) {
                    this.setState({view: this.renderTableHeaderOnly('No data found')});
                } else {
                    var sessionIdInfo = jwtDecode(this.accessToken);
                    let adminUser = false;
                    for(let i = 0; i < sessionIdInfo['cognito:groups'].length; i++) {
                        if(sessionIdInfo['cognito:groups'][i] === 'vcr-admin') {
                            adminUser = true;
                            break;
                        }
                    }
                    if(!adminUser) {
                        let factory = JSON.parse(localStorage.getItem('vcr-factory'));
                        if(factory) {
                            let pdivName = factory.key;
                            if(pdivName) {
                                for(let i = 0; i < materialsList.length; i++) {
                                    if(materialsList[i].pdiv !== pdivName) {
                                        materialsList.splice(i, 1);
                                        i--;
                                    }
                                }
                            }
                        }
                    }
                    this.setState({materialsList: materialsList}, function() {
                        this.populateFilterOptions();
                        this.applyFilters(true);
                    });

                    // Get evidences for each material
                    for(let i = 0; i < materialsList.length; i++) {
                        axios.get(settings.backend_url + '/evidence/' + materialsList[i].id)
                            .then(res => {
                                if(res.data.data != null) {
                                    const evidence = res.data.data;
                                    materialsList[i]['evidenceType'] = evidence.evidenceType;
                                    materialsList[i]['evidenceStatus'] = evidence.evidenceStatus;
                                    this.setState({materialsList: materialsList}, function() {
                                        this.populateFilterOptions();
                                        this.applyFilters(true);
                                    });
                                }
                            })
                            .catch(err => {
                                console.error(err);
                            });
                    }
                }
            })
            .catch(err => {
                this.setState({view: this.renderTableHeaderOnly('Error retrieving data')});
                console.error(err);
            });
    }

    shortenText(text, length) {
        if(text && text.length > length) {
            return text.substring(0, length) + '...';
        } else {
            return text;
        }
    }

    populateFilterOptions() {
        let uniqueSuppliers = [...new Set(this.state.materialsList.map(x => x.supplier))];
        let uniqueMaterialCategories = [...new Set(this.state.materialsList.map(x => x.materialCategory))];
        let uniqueMaterialTypes = [...new Set(this.state.materialsList.map(x => x.materialType))];
        let uniquePrimaryMaterials = [...new Set(this.state.materialsList.map(x => x.primaryMaterial))];
        let uniqueMaterialDesignations = [...new Set(this.state.materialsList.map(x => x.materialDesignation))];
        let uniqueEvidenceTypes = [...new Set(this.state.materialsList.map(x => x.evidenceType))];
        let uniqueEvidenceStatuses = [...new Set(this.state.materialsList.map(x => x.evidenceStatus))];
        let uniqueNextEvidenceRequireds = [...new Set(this.state.materialsList.map(x => x.nextEvidenceRequired))];
        let uniqueMetalAlloys = [...new Set(this.state.materialsList.map(x => x.metalAlloy))];

        uniqueSuppliers = _.sortBy(uniqueSuppliers, function (name) {
            if(name) {
                return name.toLowerCase();
            } else {
                return '';
            }
        });
        uniqueMaterialCategories = _.sortBy(uniqueMaterialCategories, function (name) {
            if(name) {
                return name.toLowerCase();
            } else {
                return '';
            }
        });
        uniqueMaterialTypes = _.sortBy(uniqueMaterialTypes, function (name) {
            if(name) {
                return name.toLowerCase();
            } else {
                return '';
            }
        });
        uniquePrimaryMaterials = _.sortBy(uniquePrimaryMaterials, function (name) {
            if(name) {
                return name.toLowerCase();
            } else {
                return '';
            }
        });
        uniqueMaterialDesignations = _.sortBy(uniqueMaterialDesignations, function (name) {
            if(name) {
                return name.toLowerCase();
            } else {
                return '';
            }
        });
        uniqueEvidenceTypes = _.sortBy(uniqueEvidenceTypes, function (name) {
            if(name) {
                return name.toLowerCase();
            } else {
                return '';
            }
        });
        uniqueEvidenceStatuses = _.sortBy(uniqueEvidenceStatuses, function (name) {
            if(name) {
                return name.toLowerCase();
            } else {
                return '';
            }
        });
        uniqueNextEvidenceRequireds = _.sortBy(uniqueNextEvidenceRequireds, function (name) {
            if(name) {
                return name.toLowerCase();
            } else {
                return '';
            }
        });
        uniqueMetalAlloys = _.sortBy(uniqueMetalAlloys, function (name) {
            if(name) {
                return name.toLowerCase();
            } else {
                return '';
            }
        });

        var self = this;
        self.state.filterOptions.supplierOptions.splice(1, self.state.filterOptions.supplierOptions.length - 1);
        self.state.filterOptions.materialCategoryOptions.splice(1, self.state.filterOptions.materialCategoryOptions.length - 1);
        self.state.filterOptions.materialTypeOptions.splice(1, self.state.filterOptions.materialTypeOptions.length - 1);
        self.state.filterOptions.primaryMaterialOptions.splice(1, self.state.filterOptions.primaryMaterialOptions.length - 1);
        self.state.filterOptions.materialDesignationOptions.splice(1, self.state.filterOptions.materialDesignationOptions.length - 1);
        self.state.filterOptions.evidenceTypeOptions.splice(1, self.state.filterOptions.evidenceTypeOptions.length - 1);
        self.state.filterOptions.evidenceStatusOptions.splice(1, self.state.filterOptions.evidenceStatusOptions.length - 1);
        self.state.filterOptions.nextEvidenceRequiredOptions.splice(1, self.state.filterOptions.nextEvidenceRequiredOptions.length - 1);
        self.state.filterOptions.metalAlloyOptions.splice(1, self.state.filterOptions.metalAlloyOptions.length - 1);
        _.each(uniqueSuppliers, function (item, index) {
            self.state.filterOptions.supplierOptions.push({key: item, text: item, value: item});
        });
        _.each(uniqueMaterialCategories, function (item, index) {
            self.state.filterOptions.materialCategoryOptions.push({key: item, text: item, value: item});
        });
        _.each(uniqueMaterialTypes, function (item, index) {
            if(item) {
                self.state.filterOptions.materialTypeOptions.push({key: item, text: item, value: item});
            }
        });
        _.each(uniquePrimaryMaterials, function (item, index) {
            if(item) {
                self.state.filterOptions.primaryMaterialOptions.push({key: item, text: item, value: item});
            }
        });
        _.each(uniqueMaterialDesignations, function (item, index) {
            self.state.filterOptions.materialDesignationOptions.push({key: item, text: item, value: item});
        });
        _.each(uniqueEvidenceTypes, function (item, index) {
            if(item) {
                self.state.filterOptions.evidenceTypeOptions.push({key: item, text: item, value: item});
            }
        });
        _.each(uniqueEvidenceStatuses, function (item, index) {
            if(item) {
                self.state.filterOptions.evidenceStatusOptions.push({key: item, text: item, value: item});
            }
        });
        _.each(uniqueNextEvidenceRequireds, function (item, index) {
            if(item) {
                self.state.filterOptions.nextEvidenceRequiredOptions.push({key: item, text: item, value: item});
            }
        });
        _.each(uniqueMetalAlloys, function (item, index) {
            if(item) {
                self.state.filterOptions.metalAlloyOptions.push({key: item, text: item, value: item});
            }
        });
        this.setDefaultFilters();
    }

    setDefaultFilters(callback) {
        let keys = Object.keys(this.state.selectedFilters);
        let defaultFilters = this.state.defaultFilters;
        for(let i = 0; i < keys.length; i++) {
            if(this.state.selectedFilters[keys[i]]) {
                let filterOptions = this.state.filterOptions[keys[i] + 'Options'];
                for(let j = 0; j < filterOptions.length; j++) {
                    if(this.state.selectedFilters[keys[i]] === filterOptions[j].value) {
                        defaultFilters[keys[i]] = j;
                    }
                }
            }
        }
        this.setState({defaultFilters: defaultFilters});
        
    }

    changeView = (view) => {
        if(view === null) {
            this.setDefaultFilters();
            if(this.state.materialsList.length < 1) {
                this.setState({view: this.renderTableHeaderOnly('No data found')});
            } else {
                this.setState({view: this.renderTable()});
            }
        } else {
            this.setState({view: view});
        }
    }

    updateMaterial = (material) => {
        for(let i = 0; i < this.state.materialsList.length; i++) {
            if(this.state.materialsList[i].id === material.id) {
                let materialsList = this.state.materialsList;
                materialsList[i] = material;
                this.setState({materialsList: materialsList}, function() {
                    this.populateFilterOptions();
                    this.applyFilters(false);
                });
            }
        }
    }

    showFilters = () => {
        if(!this.state.filters) {
            this.setState({filters: (<div className="filter-box">
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Select fluid name='supplier' options={this.state.filterOptions.supplierOptions}
                                    defaultValue={this.state.filterOptions.supplierOptions[this.state.defaultFilters['supplier']].value}
                                    onChange={this.handleChange} />
                        <Form.Select fluid name='materialDesignation' options={this.state.filterOptions.materialDesignationOptions}
                                    defaultValue={this.state.filterOptions.materialDesignationOptions[this.state.defaultFilters['materialDesignation']].value}
                                    onChange={this.handleChange} />
                        <Form.Select fluid name='materialType' options={this.state.filterOptions.materialTypeOptions}
                                    defaultValue={this.state.filterOptions.materialTypeOptions[this.state.defaultFilters['materialType']].value}
                                    onChange={this.handleChange} />
                        <Form.Select fluid name='materialCategory' options={this.state.filterOptions.materialCategoryOptions}
                                    defaultValue={this.state.filterOptions.materialCategoryOptions[this.state.defaultFilters['materialCategory']].value}
                                    onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Select fluid name='primaryMaterial' options={this.state.filterOptions.primaryMaterialOptions}
                                    defaultValue={this.state.filterOptions.primaryMaterialOptions[this.state.defaultFilters['primaryMaterial']].value}
                                    onChange={this.handleChange} />
                        <Form.Select fluid name='metalAlloy' options={this.state.filterOptions.metalAlloyOptions}
                                    defaultValue={this.state.filterOptions.metalAlloyOptions[this.state.defaultFilters['metalAlloy']].value}
                                    onChange={this.handleChange} />
                        <Form.Select fluid name='evidenceType' options={this.state.filterOptions.evidenceTypeOptions}
                                    defaultValue={this.state.filterOptions.evidenceTypeOptions[this.state.defaultFilters['evidenceType']].value}
                                    onChange={this.handleChange} />
                        <Form.Select fluid name='evidenceStatus' options={this.state.filterOptions.evidenceStatusOptions}
                                    defaultValue={this.state.filterOptions.evidenceStatusOptions[this.state.defaultFilters['evidenceStatus']].value}
                                    onChange={this.handleChange} />
                        <Form.Select fluid name='nextEvidenceRequired' options={this.state.filterOptions.nextEvidenceRequiredOptions}
                                    defaultValue={this.state.filterOptions.nextEvidenceRequiredOptions[this.state.defaultFilters['nextEvidenceRequired']].value}
                                    onChange={this.handleChange} />
                    </Form.Group>
                </Form>
            </div>)}, function() {
                this.changeView(null);
            });
        } else {
            this.setState({filters: null}, function() {
                this.changeView(null);
            });
        }
    };

    showEditMode = () =>  {
        if(!this.readOnly) {
            if(!this.state.showCheckboxes) {
                this.setState({
                    showCheckboxes: {display: 'none'},
                    editForm: null,
                    showEditPanelText: 'SHOW EDIT PANEL'
                },
                () => {
                    this.changeView(null)
                });
            } else {
                this.setState({
                    showCheckboxes: null,
                    editForm: this.renderEditForm(),
                    showEditPanelText: 'HIDE EDIT PANEL'
                },
                () => {
                    this.changeView(null)
                });
            }
        }
    }

    applyFilters = (reload) => {
        let filteredList = [];
        for(let i = 0; i < this.state.materialsList.length; i++) {
            if(this.state.selectedFilters.supplier !== null) {
                if(this.state.materialsList[i].supplier !== this.state.selectedFilters.supplier) {
                    continue;
                }
            }
            if(this.state.selectedFilters.materialCategory !== null) {
                if(this.state.materialsList[i].materialCategory !== this.state.selectedFilters.materialCategory) {
                    continue;
                }
            }
            if(this.state.selectedFilters.materialType !== null) {
                if(this.state.materialsList[i].materialType !== this.state.selectedFilters.materialType) {
                    continue;
                }
            }
            if(this.state.selectedFilters.primaryMaterial !== null) {
                if(this.state.materialsList[i].primaryMaterial !== this.state.selectedFilters.primaryMaterial) {
                    continue;
                }
            }
            if(this.state.selectedFilters.materialDesignation !== null) {
                if(this.state.materialsList[i].materialDesignation !== this.state.selectedFilters.materialDesignation) {
                    continue;
                }
            }
            if(this.state.selectedFilters.evidenceType !== null) {
                if(this.state.materialsList[i].evidenceType !== this.state.selectedFilters.evidenceType) {
                    continue;
                }
            }
            if(this.state.selectedFilters.evidenceStatus !== null) {
                if(this.state.materialsList[i].evidenceStatus !== this.state.selectedFilters.evidenceStatus) {
                    continue;
                }
            }
            if(this.state.selectedFilters.nextEvidenceRequired !== null) {
                if(this.state.materialsList[i].nextEvidenceRequired !== this.state.selectedFilters.nextEvidenceRequired) {
                    continue;
                }
            }
            if(this.state.selectedFilters.metalAlloy !== null) {
                if(this.state.materialsList[i].metalAlloy !== this.state.selectedFilters.metalAlloy) {
                    continue;
                }
            }
            filteredList.push(this.state.materialsList[i]);
        }
        
        let checkboxes = {};
        for(let i = 0; i < filteredList.length; i++) {
            checkboxes[filteredList[i].id] = false;
        }

        if(this.state.allChecked) {
            this.toggleAllCheckboxes();
        }
        this.setState({checkboxes: checkboxes, filteredList: filteredList, allChecked: false, selectedCheckboxes: 0}, () => {
            if(reload) {
                this.changeView(null);
            }
        });
    }

    handleMultiUpdate = (event, { name, value }) => {
        if(name === 'evidenceType') {
            this.evidenceInfo[name] = value;
        }
        if(name === 'evidenceStatus') {
            this.evidenceInfo[name] = value;
            if(value === 'Evidence approved') {
                this.evidenceInfo.dateApproved = this.dateToString(new Date());
                let sessionIdInfo = jwtDecode(this.accessToken);
                this.evidenceInfo.approver = sessionIdInfo.username;
            } else {
                this.evidenceInfo.dateApproved = null;
                this.evidenceInfo.approver = null;
            }
        }
        if(name === 'dateRequired') {
            this.evidenceInfo[name] = value;
        }
        if(name === 'dateRequested') {
            this.evidenceInfo[name] = value;
        }
        if(name === 'dateReceived') {
            this.evidenceInfo[name] = value;
        }
        if(name === 'evidenceNotes') {
            this.evidenceInfo[name] = value;
        }
        if(name === 'reachStatus') {
            this.complianceInfo[name] = value;
        }
        if(name === 'reachNotes') {
            this.complianceInfo[name] = value;
        }
        if(name === 'rohsStatus') {
            this.complianceInfo[name] = value;
        }
        if(name === 'rohsNotes') {
            this.complianceInfo[name] = value;
        }
        if(name === 'declarableSubstancesStatus') {
            this.complianceInfo[name] = value;
        }
        if(name === 'declarableNotes') {
            this.complianceInfo[name] = value;
        }
        if(name === 'prohibitedSubstancesStatus') {
            this.complianceInfo[name] = value;
        }
        if(name === 'prohibitedNotes') {
            this.complianceInfo[name] = value;
        }
        if(name === 'complianceNotes') {
            this.complianceInfo[name] = value;
        }
        this.setState({editForm: this.renderEditForm()}, () => this.changeView(null));
    };

    handleChange = (event, { name, value }) => {
        if(value.toLowerCase() === 'vendor') {
            value = 'supplier';
        }
        if(value.toLowerCase() === 'material category') {
            value = 'materialCategory';
        }
        if(value.toLowerCase() === 'material type') {
            value = 'materialType';
        }
        if(value.toLowerCase() === 'primary material') {
            value = 'primaryMaterial';
        }
        if(value.toLowerCase() === 'material designation') {
            value = 'materialDesignation';
        }
        if(value.toLowerCase() === 'evidence type') {
            value = 'evidenceType';
        }
        if(value.toLowerCase() === 'evidence status') {
            value = 'evidenceStatus';
        }
        if(value.toLowerCase() === 'next evidence required') {
            value = 'nextEvidenceRequired';
        }
        if(value.toLowerCase() === 'factory') {
            value = 'pdiv';
        }
        if(value.toLowerCase() === 'metal alloy') {
            value = 'metalAlloy';
        }
        if(value !== name) {
            let filters = this.state.selectedFilters;
            filters[name] = value;
            this.setState({selectedFilters: filters}, function() {
                this.applyFilters(true);
            });
        } else {
            let filters = this.state.selectedFilters;
            filters[name] = null;
            this.setState({selectedFilters: filters}, function() {
                this.applyFilters(true);
            });
        }
    };

    handleSort = (clickedColumn) => () => {
        if (this.state.sortedColumn !== clickedColumn) {
            this.setState({
                sortedColumn: clickedColumn,
                filteredList: _.sortBy(this.state.filteredList, function (material) {
                    return material[clickedColumn].toLowerCase();
                }),
                sortDirection: 'ascending',
            });
        } else {    
            this.setState({
                filteredList: this.state.filteredList.reverse(),
                sortDirection: this.state.sortDirection === 'ascending' ? 'descending' : 'ascending',
            });
        }
        this.changeView(null);
    }

    deleteMaterial = (materialId) => {
        axios.delete(settings.backend_url + '/materials/' + materialId)
            .then(res => {
                let materialsList = this.state.materialsList;
                for(let i = 0; i < materialsList.length; i++) {
                    if(materialsList[i].id === materialId) {
                        materialsList.splice(i, 1);
                        this.setState({materialsList: materialsList}, function() {
                            this.applyFilters(true);
                        });
                    }
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    cancelDelete = () => {
        this.setState({ deleteConfirmOpen: false }, function() {
            this.changeView(null);
        });
    }

    confirmDelete = () => {
        this.deleteMaterial(this.state.deleteData);
        this.setState({ deleteData: null, deleteConfirmOpen: false }, function() {
            this.changeView(null);
        });
    }

    openConfirmDialog = (e, material) => {
        if(!this.readOnly) {
            let headerText = 'Delete material ' + material.supplier + '?';
            e.preventDefault();
            e.stopPropagation();
            this.setState({confirmHeader: headerText , deleteData: material.id, deleteConfirmOpen: true}, function() {
                this.changeView(null);
            });
        }
    }

    checkboxClicked = (e, material) => {
        e.preventDefault();
        e.stopPropagation();

        let checkboxes = this.state.checkboxes;
        let selectedCheckboxes = this.state.selectedCheckboxes
        checkboxes[material.id] = !checkboxes[material.id];
        if(checkboxes[material.id]) {
            selectedCheckboxes++;
        } else {
            selectedCheckboxes--;
        }
        this.setState({checkboxes: checkboxes, selectedCheckboxes: selectedCheckboxes}, () => {
            this.setState({editForm: this.renderEditForm()}, () => this.changeView(null));
        });
    }

    toggleAllCheckboxes = () => {
        let checkboxes = this.state.checkboxes;
        let selectedCheckboxes = 0;
        let keys = Object.keys(checkboxes);
        if(!this.state.allChecked) {
            for(let i = 0; i < keys.length; i++) {
                checkboxes[keys[i]] = true;
                selectedCheckboxes++;
            }
        } else {
            for(let i = 0; i < keys.length; i++) {
                checkboxes[keys[i]] = false;
            }
        }
        this.setState({allChecked: !this.state.allChecked, checkboxes: checkboxes, selectedCheckboxes: selectedCheckboxes}, () => {
            this.setState({editForm: this.renderEditForm()}, () => this.changeView(null));
        });
    }

    dateToString(date) {
        let d = '';
        try {
            d = date.getMonth()+1 + '/' + date.getDate() + '/' + date.getFullYear();
        } catch(err) {
            d = date;
        }
        return d;
    }

    onDateChange = (type, date) => {
        if(type === 'required') {
            this.evidenceInfo.dateRequired = this.dateToString(date);
        }
        if(type === 'requested') {
            this.evidenceInfo.dateRequested = this.dateToString(date);
        }
        if(type === 'received') {
            this.evidenceInfo.dateReceived = this.dateToString(date);
        }
    };

    applyUpdates = () => {
        const keys = Object.keys(this.state.checkboxes);
        var evidenceInfo;
        var updatedEvidenceInfo = this.evidenceInfo;
        var updatedComplianceInfo = this.complianceInfo;
        const { showLoading, hideLoading } = this.props;
        let hasError = false;
        let errorText = [];
        for(let i = 0; i < keys.length; i++) {
            if(this.state.checkboxes[keys[i]]) {
                axios.get(settings.backend_url + '/evidence/' + keys[i])
                .then(res => {
                    if(res.data.data != null) {
                        evidenceInfo = res.data.data;
                    }  else {
                        evidenceInfo = {
                            dateRequired: this.dateToString(new Date()),
                            dateRequested: ' ',
                            dateReceived: ' ',
                            dateApproved: ' ',
                            evidenceStatus: ' ',
                            evidenceType: ' ',
                            material: keys[i],
                            evidenceNotes: ' ',
                            complianceNotes: ' ',
                            reachNotes: ' ',
                            rohsNotes: ' ',
                            declarableNotes: ' ',
                            prohibitedNotes: ' ',
                            reach: ' ',
                            rohs: ' ',
                            declarable: ' ',
                            prohibited: ' ',
                            approver: ' '
                        }
                    }

                    if(updatedEvidenceInfo.evidenceType) {
                        evidenceInfo.evidenceType = updatedEvidenceInfo.evidenceType
                    }
                    if(updatedEvidenceInfo.evidenceStatus) {
                        evidenceInfo.evidenceStatus = updatedEvidenceInfo.evidenceStatus
                        evidenceInfo.dateApproved = this.dateToString(new Date());
                        let sessionIdInfo = jwtDecode(this.accessToken);
                        evidenceInfo.approver = sessionIdInfo.username;
                        evidenceInfo.approver = updatedEvidenceInfo.approver;
                    }
                    if(updatedEvidenceInfo.dateRequired) {
                        evidenceInfo.dateRequired = updatedEvidenceInfo.dateRequired
                    }
                    if(updatedEvidenceInfo.dateRequested) {
                        evidenceInfo.dateRequested = updatedEvidenceInfo.dateRequested
                    }
                    if(updatedEvidenceInfo.dateReceived) {
                        evidenceInfo.dateReceived = updatedEvidenceInfo.dateReceived
                    }
                    if(updatedEvidenceInfo.dateApproved) {
                        evidenceInfo.dateApproved = updatedEvidenceInfo.dateApproved;
                        let sessionIdInfo = jwtDecode(this.accessToken);
                        evidenceInfo.approver = sessionIdInfo.username;
                    } else {
                        evidenceInfo.dateApproved = ' ';
                        evidenceInfo.approver = ' ';
                    }
                    if(updatedEvidenceInfo.evidenceNotes) {
                        evidenceInfo.evidenceNotes = updatedEvidenceInfo.evidenceNotes;
                    }
                    if(updatedComplianceInfo.complianceNotes) {
                        evidenceInfo.complianceNotes = updatedComplianceInfo.complianceNotes;
                    }
                    if(updatedComplianceInfo.reachNotes) {
                        evidenceInfo.reachNotes = updatedComplianceInfo.reachNotes;
                    }
                    if(updatedComplianceInfo.rohsNotes) {
                        evidenceInfo.rohsNotes = updatedComplianceInfo.rohsNotes;
                    }
                    if(updatedComplianceInfo.declarableNotes) {
                        evidenceInfo.declarableNotes = updatedComplianceInfo.declarableNotes;
                    }
                    if(updatedComplianceInfo.prohibitedNotes) {
                        evidenceInfo.prohibitedNotes = updatedComplianceInfo.prohibitedNotes;
                    }
                    if(updatedComplianceInfo.reachStatus) {
                        evidenceInfo.reach = updatedComplianceInfo.reachStatus;
                    }
                    if(updatedComplianceInfo.rohsStatus) {
                        evidenceInfo.rohs = updatedComplianceInfo.rohsStatus;
                    }
                    if(updatedComplianceInfo.declarableSubstancesStatus) {
                        evidenceInfo.declarable = updatedComplianceInfo.declarableSubstancesStatus;
                    }
                    if(updatedComplianceInfo.prohibitedSubstancesStatus) {
                        evidenceInfo.prohibited = updatedComplianceInfo.prohibitedSubstancesStatus;
                    }

                    if(res.data.data != null) {
                        axios.put(settings.backend_url + '/evidence/',
                            evidenceInfo)
                        .then(res => {
                            // pass
                        })
                        .catch(err => {
                            hasError = true;
                            errorText.push('Error updating evidence for material ' + keys[i]);
                            console.error(err);
                        });
                    } else {
                        axios.post(settings.backend_url + '/evidence/',
                            evidenceInfo)
                        .then(res => {
                            // pass
                        })
                        .catch(err => {
                            hasError = true;
                            errorText.push('Error creating evidence for material ' + keys[i]);
                            console.error(err);
                        });
                    }
                })
                .catch(err => {
                    hasError = true;
                    errorText.push('Error retrieving evidence for material ' + keys[i]);
                    console.error(err);
                });
                
                for(let j = 0; j < this.state.documents.length; j++) {
                    let fileName = keys[i] + '_' + this.state.documents[j].name + '.' + this.state.documents[j].type;
                    this.storage.uploadFile({
                        name: fileName,
                        content: this.state.documents[j].content
                    }, showLoading, hideLoading);
                }
            }
        }

        this.evidenceInfo = {
            evidenceType: null,
            status: null,
            dateRequired: null,
            dateRequested: null,
            dateReceived: null,
            dateApproved: null,
            evidenceNotes: null
        };

        this.complianceInfo = {
            reachStatus: null,
            reachNotes: null,
            rohsStatus: null,
            rohsNotes: null,
            declarableNotes: null,
            prohibitedNotes: null,
            declarableSubstancesStatus: null,
            prohibitedSubstancesStatus: null,
            complianceNotes: null
        };
        
        this.setState({documents: []}, () => {
            this.setState({editForm: this.renderEditForm()}, () => this.changeView(null));
        });
        if (hasError) {
            alert(errorText.join('\n'));
        }else {
            alert('Changes applied to selected materials');
        }
        this.refreshMaterials();
    }

    clearUpdates = () => {
        this.evidenceInfo = {
            evidenceType: null,
            status: null,
            dateRequired: null,
            dateRequested: null,
            dateReceived: null,
            dateApproved: null,
            evidenceNotes: null
        };

        this.complianceInfo = {
            reachStatus: null,
            reachNotes: null,
            rohsStatus: null,
            rohsNotes: null,
            declarableNotes: null,
            prohibitedNotes: null,
            declarableSubstancesStatus: null,
            prohibitedSubstancesStatus: null,
            complianceNotes: null
        };

        this.setState({documents: []}, () => {
            this.setState({editForm: this.renderEditForm()}, () => this.changeView(null));
        });
    }

    getFileName = (path) => {
        let fileName;

        if(path.indexOf('/') > -1) {
            fileName = path.substring(path.lastIndexOf('/')+1, path.length);
        } else if(path.indexOf('\\') > -1) {
            fileName = path.substring(path.lastIndexOf('\\')+1, path.length);
        } else {
            fileName = path;
        }

        if(fileName.lastIndexOf('.') > -1) {
            return fileName.substring(0, fileName.lastIndexOf('.'));
        } else {
            return fileName;
        }
    }

    getFileEnding = (file) => {
        return file.substring(file.lastIndexOf('.')+1, file.length);
    }

    addDocument = (event) => {
        let documents = this.state.documents;
        for(let i = 0; i < event.target.files.length; i++) {
            let fileName = this.getFileName(event.target.files[i].name);
            let fileEnding = this.getFileEnding(event.target.files[i].name);
            let content = event.target.files[i];
            documents.push({type: fileEnding, name: fileName, content: content});
        }
        this.setState({documents: documents}, () => {
            this.setState({editForm: this.renderEditForm()}, () => this.changeView(null));
        });
    }

    renderEditForm = () => {
        const CustomRequiredInput = ({ value, onClick }) => {
            if(!this.evidenceInfo.dateRequired || this.evidenceInfo.dateRequired === ' ') {
                return <Button onClick={onClick} style={{width: '8.1rem', height: '2.6rem'}}>
                    Select Date
                </Button>
            } else {
                return <Button onClick={onClick} style={{width: '8.1rem', height: '2.6rem'}}>
                    {this.evidenceInfo.dateRequired}
                </Button>
            }
        };

        const CustomRequestedInput = ({ value, onClick }) => {
            if(!this.evidenceInfo.dateRequested || this.evidenceInfo.dateRequested === ' ') {
                return <Button onClick={onClick} style={{width: '8.1rem', height: '2.6rem'}}>
                    Select Date
                </Button>
            } else {
                return <Button onClick={onClick} style={{width: '8.1rem', height: '2.6rem'}}>
                    {this.evidenceInfo.dateRequested}
                </Button>
            }
        };

        const CustomReceivedInput = ({ value, onClick }) => {
            if(!this.evidenceInfo.dateReceived || this.evidenceInfo.dateReceived === ' ') {
                return <Button onClick={onClick} style={{width: '8.1rem', height: '2.6rem'}}>
                    Select Date
                </Button>
            } else {
                return <Button onClick={onClick} style={{width: '8.1rem', height: '2.6rem'}}>
                    {this.evidenceInfo.dateReceived}
                </Button>
            }
        };

        const FORMAT = 'MM/dd/yyyy';

        return (
            <div style={{background: 'white', marginBottom: '4rem', padding: '2rem 2rem 5rem 2rem'}}>
                <Header as ='h3'>EVIDENCE INFO</Header>
                <Form>
                    <Form.Group widths='equal' style={{width: '70%'}}>
                        <Form.Input fluid label='Evidence Type' name='evidenceType' placeholder='Enter Evidence Type'
                                    onChange={this.handleMultiUpdate} />
                        <Form.Select fluid label='Status' name='evidenceStatus' options={this.multiUpdateOptions.statusOptions}
                                    onChange={this.handleMultiUpdate} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <div style={{paddingLeft: '0.4rem', width: '25%'}}>
                            <div>
                                <label>Date Expired</label>
                            </div>
                            <div style={{paddingTop: '0.33rem'}}>
                                <DatePicker
                                    selected={this.evidenceInfo.dateRequired ? new Date(this.evidenceInfo.dateRequired) : new Date()}
                                    onChange={date => this.onDateChange('required', date)}
                                    customInput={<CustomRequiredInput />}
                                    dateFormat={FORMAT}
                                    placeholderText="Click to select a date"
                                />
                            </div>
                        </div>
                        <div style={{paddingLeft: '0.4rem', width: '25%'}}>
                            <div>
                                <label>Date Requested</label>
                            </div>
                            <div style={{paddingTop: '0.33rem'}}>
                                <DatePicker
                                    selected={this.evidenceInfo.dateRequested ? new Date(this.evidenceInfo.dateRequested) : new Date()}
                                    onChange={date => this.onDateChange('requested', date)}
                                    customInput={<CustomRequestedInput />}
                                    dateFormat={FORMAT}
                                    placeholderText="Click to select a date"
                                />
                            </div>
                        </div>
                        <div style={{paddingLeft: '0.4rem', width: '25%'}}>
                            <div>
                                <label>Date Received</label>
                            </div>
                            <div style={{paddingTop: '0.33rem'}}>
                                <DatePicker
                                    selected={this.evidenceInfo.dateReceived ? new Date(this.evidenceInfo.dateReceived) : new Date()}
                                    onChange={date => this.onDateChange('received', date)}
                                    customInput={<CustomReceivedInput />}
                                    dateFormat={FORMAT}
                                    placeholderText="Click to select a date"
                                />
                            </div>
                        </div>
                        <div style={{paddingLeft: '0.4rem', width: '25%'}}>
                            <div>
                                <label>Date Approved</label>
                            </div>
                            <div>
                                {this.evidenceInfo.dateApproved}
                            </div>
                        </div>
                    </Form.Group>
                    <Form.Group widths='equal' style={{width: '70%'}}>
                        <Form.TextArea style={{ fontFamily: 'SKF-Chevin' }} label='Evidence Notes' name='evidenceNotes'
                                    placeholder='Enter notes about the evidence'
                                    onChange={this.handleMultiUpdate} />
                    </Form.Group>
                </Form>

                <Header as ='h3'>COMPLIANCE INFO</Header>
                <Form>
                    <Form.Group widths='equal' style={{width: '70%'}}>
                        <Form.Select fluid label='REACH Compliance Status' name='reachStatus' options={this.multiUpdateOptions.reachStatusOptions}
                                    onChange={this.handleMultiUpdate} />
                        <Form.Input fluid label='REACH Compliance Notes' name='reachNotes' placeholder='Enter notes'
                                    onChange={this.handleMultiUpdate} />
                    </Form.Group>
                    <Form.Group widths='equal' style={{width: '70%'}}>
                        <Form.Select fluid label='ROHS Compliance Status' name='rohsStatus' options={this.multiUpdateOptions.rohsStatusOptions}
                                    onChange={this.handleMultiUpdate} />
                        <Form.Input fluid label='ROHS Compliance Notes' name='rohsNotes' placeholder='Enter notes'
                                    onChange={this.handleMultiUpdate} />
                    </Form.Group>
                    <Form.Group widths='equal' style={{width: '70%'}}>
                        <Form.Select fluid label='Declarable Substances Status' name='declarableSubstancesStatus' options={this.multiUpdateOptions.declarableSubstancesStatus}
                                    onChange={this.handleMultiUpdate} />
                        <Form.Input fluid label='Declarable Compliance Notes' name='declarableNotes' placeholder='Enter notes'
                                    onChange={this.handleMultiUpdate} />            
                    </Form.Group>
                    <Form.Group widths='equal' style={{width: '70%'}}>
                        <Form.Select fluid label='Prohibited Substances Status' name='prohibitedSubstancesStatus' options={this.multiUpdateOptions.prohibitedSubstancesStatus}
                                    onChange={this.handleMultiUpdate} />
                        <Form.Input fluid label='Prohibited Compliance Notes' name='prohibitedNotes' placeholder='Enter notes'
                                    onChange={this.handleMultiUpdate} />            
                    </Form.Group>
                    <Form.Group widths='equal' style={{width: '70%'}}>
                        <Form.TextArea style={{ fontFamily: 'SKF-Chevin' }} label='Compliance Notes' name='complianceNotes'
                                    placeholder='Enter notes about the compliance'
                                    onChange={this.handleMultiUpdate} />
                    </Form.Group>
                </Form>

                <Header as ='h3'>DOCUMENT INFO</Header>
                <Form>
                    <Form.Group widths='equal' style={{width: '40%', float: 'right'}}>
                        <input type="file" id="file" hidden onChange={this.addDocument} accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf" multiple/>
                        <Button as="label" htmlFor="file" type="button">
                            UPLOAD
                        </Button>
                    </Form.Group>
                    <Table sortable singleLine style={{width: '70%'}}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '10%'}}>
                                    Type
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Name
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                
                        <Table.Body>
                            {this.state.documents.map((document) => {
                                return <Table.Row className="cursor-pointer">
                                <Table.Cell>{document.type}</Table.Cell>
                                <Table.Cell>{document.name}</Table.Cell>
                                </Table.Row>
                            })}
                        </Table.Body>
                    </Table>
                    <Form.Group widths='equal' style={{float: 'right', paddingTop: '1rem'}}>
                        <Button
                            content={'APPLY TO SELECTED (' + `${this.state.selectedCheckboxes}` + ')'}
                            type='submit'
                            onClick={this.applyUpdates}
                        />
                    </Form.Group>
                </Form>
            </div>
        );
    }

    renderTableHeaderOnly(noData) {
        return (
            <div>
                <Header as='h1'>MATERIALS</Header>
                <Grid.Column className="filter-grid">
                    <div>
                        <Button
                            content='SHOW FILTERS'
                            type='submit'
                            floated='right'
                        />
                    </div>
                    <div>
                        <Button
                            content={this.state.showEditPanelText}
                            type='submit'
                            floated='right'
                        />
                    </div>
                    <div>
                        <ExcelFile element={<Button floated='right'>DOWNLOAD AS EXCEL</Button>}>
                            <ExcelSheet data={this.state.filteredList} name="Materials">
                                <ExcelColumn label="ID" value="id" />
                                <ExcelColumn label="Factory" value="pdiv" />
                                <ExcelColumn label="Vendor" value="supplier" />
                                <ExcelColumn label="Material Category" value="materialCategory" />
                                <ExcelColumn label="Material Type" value="materialType" />
                                <ExcelColumn label="Primary Material" value="primaryMaterial" />
                                <ExcelColumn label="Material Designation" value="materialDesignation" />
                                <ExcelColumn label="Purchaser" value="purchaser" />
                                <ExcelColumn label="Material Status" value="materialStatus" />
                                <ExcelColumn label="Evidence Type" value="evidenceType" />
                                <ExcelColumn label="Evidence Status" value="evidenceStatus" />
                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                </Grid.Column>
                <Table sortable singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Vendor</Table.HeaderCell>
                            <Table.HeaderCell>Material Category</Table.HeaderCell>
                            <Table.HeaderCell>Material Type</Table.HeaderCell>
                            <Table.HeaderCell>Primary Material</Table.HeaderCell>
                            <Table.HeaderCell>Material Designation</Table.HeaderCell>
                            <Table.HeaderCell>Evidence Type</Table.HeaderCell>
                            <Table.HeaderCell>Evidence Status</Table.HeaderCell>
                            <Table.HeaderCell>Next Evidence Required</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '20px'}}></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                </Table>
                <div className="no-data">
                    {noData}
                </div>
            </div>
        );
    }

    renderTable = () => {
        const { showLoading, hideLoading } = this.props;
        return (
            <div>
                {this.state.editForm}
                <Header as='h1'>MATERIALS</Header>
                <Grid.Column className="filter-grid">
                    <div>
                        <Button
                            content='SHOW FILTERS'
                            type='submit'
                            onClick={this.showFilters}
                            floated='right'
                        />
                    </div>
                    <div>
                        <Button
                            content={this.state.showEditPanelText}
                            type='submit'
                            onClick={this.showEditMode}
                            floated='right'
                        />
                    </div>
                    <div>
                        <ExcelFile element={<Button floated='right'>DOWNLOAD AS EXCEL</Button>}>
                            <ExcelSheet data={this.state.filteredList} name="Materials">
                                <ExcelColumn label="ID" value="id" />
                                <ExcelColumn label="Factory" value="pdiv" />
                                <ExcelColumn label="Vendor" value="supplier" />
                                <ExcelColumn label="Material Category" value="materialCategory" />
                                <ExcelColumn label="Material Type" value="materialType" />
                                <ExcelColumn label="Primary Material" value="primaryMaterial" />
                                <ExcelColumn label="Material Designation" value="materialDesignation" />
                                <ExcelColumn label="Purchaser" value="purchaser" />
                                <ExcelColumn label="Material Status" value="materialStatus" />
                                <ExcelColumn label="Evidence Type" value="evidenceType" />
                                <ExcelColumn label="Evidence Status" value="evidenceStatus" />
                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                </Grid.Column>
                <div>
                    {this.state.filters}
                </div>
                <Table sortable singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                <Checkbox checked={this.state.allChecked} style={this.state.showCheckboxes} onClick={(e) => this.toggleAllCheckboxes()}></Checkbox>
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.state.sortedColumn === 'supplier' ? this.state.sortDirection : null}
                                onClick={this.handleSort('supplier')}
                            >
                                Vendor
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.state.sortedColumn === 'materialCategory' ? this.state.sortDirection : null}
                                onClick={this.handleSort('materialCategory')}
                            >
                                Material Category
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.state.sortedColumn === 'materialType' ? this.state.sortDirection : null}
                                onClick={this.handleSort('materialType')}
                            >
                                Material Type
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.state.sortedColumn === 'primaryMaterial' ? this.state.sortDirection : null}
                                onClick={this.handleSort('primaryMaterial')}
                            >
                                Primary Material
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.state.sortedColumn === 'materialDesignation' ? this.state.sortDirection : null}
                                onClick={this.handleSort('materialDesignation')}
                            >
                                Material Designation
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.state.sortedColumn === 'evidenceType' ? this.state.sortDirection : null}
                                onClick={this.handleSort('evidenceType')}
                            >
                                Evidence Type
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.state.sortedColumn === 'evidenceStatus' ? this.state.sortDirection : null}
                                onClick={this.handleSort('evidenceStatus')}
                            >
                                Evidence Status
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.state.sortedColumn === 'nextEvidenceRequired' ? this.state.sortDirection : null}
                                onClick={this.handleSort('nextEvidenceRequired')}
                            >
                                Next Evidence Required
                            </Table.HeaderCell>
                            <Table.HeaderCell style={{width: '20px'}}>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
            
                    <Table.Body>
                        {this.state.filteredList.map((material) => {
                            return <Table.Row className="cursor-pointer" onClick={() => this.setState({view: <Material readOnly={this.readOnly} attributes={this.attributes} changeView={this.changeView} updateMaterial={this.updateMaterial} material={material} 
                                showLoading={showLoading} hideLoading={hideLoading}/>})}>
                            <Table.Cell>
                                <Checkbox style={this.state.showCheckboxes}
                                    onClick={(e) => this.checkboxClicked(e, material)} 
                                    checked={this.state.checkboxes[material.id]}
                                />
                            </Table.Cell>
                            <Table.Cell>{this.shortenText(material.supplier, 25)}</Table.Cell>
                            <Table.Cell>{material.materialCategory}</Table.Cell>
                            <Table.Cell>{material.materialType}</Table.Cell>
                            <Table.Cell>{material.primaryMaterial}</Table.Cell>
                            <Table.Cell>{this.shortenText(material.materialDesignation, 25)}</Table.Cell>
                            <Table.Cell>{material.evidenceType}</Table.Cell>
                            <Table.Cell>{material.evidenceStatus}</Table.Cell>
                            <Table.Cell>{material.nextEvidenceRequired}</Table.Cell>
                            <Table.Cell onClick={(e) => this.openConfirmDialog(e, material)}><img src="images/bin-1.png" alt='left arrow'/></Table.Cell>
                            </Table.Row>
                        })}
                    </Table.Body>
                </Table>
                <Confirm
                    open={this.state.deleteConfirmOpen}
                    header={this.state.confirmHeader}
                    onCancel={this.cancelDelete}
                    onConfirm={this.confirmDelete}
                />
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.state.view}
            </div>
        );
    }
}

export default Materials;
