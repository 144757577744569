import React, { Component } from 'react'
import { Button, Form, Header, Modal } from 'semantic-ui-react'
import axios from 'axios';

import * as settings from '../settings';

class CreateMaterialModal extends Component {
    
    constructor(props) {
        super(props);

        this.admin = props.admin;
        this.factory = props.factory;
        this.attributes = props.attributes;
        this.refreshMaterials = props.refreshMaterials;
        this.filteredPurchasers = null;
        this.filteredVendors = null;
        this.pdivOptions = [];

        this.state = {
            materialDesignation: '',
            pdiv: null,
            purchaser: null,
            supplier: null,
            supplierDesignation: '',
            originalManufacturer: '',
            materialCategory: null,
            materialType: '',
            primaryMaterial: '',
            metalAlloy: '',
            notes: '',
            materialDesignationError: false,
            supplierDesignationError: false,
            materialTypeError: false,
            notesError: false
        };
    }

    componentDidMount() {
      if(!this.admin) {
        let newPdivOptions = [];
        // set pdivOptions by factories from local storage
        let factories = JSON.parse(localStorage.getItem('vcr-factories'));
        for(let i = 0; i < factories.length; i++) {
          newPdivOptions.push({
            key: factories[i].key,
            text: factories[i].text,
            value: {id: factories[i].id, name: factories[i].text}
          });
        }
        this.attributes.pdivOptions = newPdivOptions;
      }else {
        axios.get(settings.backend_url + '/attributes')
        .then(res => {
          let newPdivOptions = [];
          const attributes = res.data.data;
          for(let i = 0; i < attributes.pdiv.length; i++) {
            newPdivOptions.push({
              key: attributes.pdiv[i].name,
              text: attributes.pdiv[i].name,
              value: {id: attributes.pdiv[i].id, name: attributes.pdiv[i].name}
            })
          }
          this.attributes.pdivOptions = newPdivOptions;
        })
        .catch(err => {
            console.error(err);
        });
      }
    }

    missingRequired = () => {
        return (
            !this.state.materialDesignation || !this.state.pdiv || !this.state.purchaser 
            || !this.state.supplier || !this.state.materialCategory
        );
    };

    validateContent = (data, error) => {
      const jsonRegExp = /\{.*:.*\}/g;
      const wordRegExp = /(?:^|\W)ALL_ATTRIBUTES|AttributeValueList|ComparisonOperator(?:$|\W)/g;
      if (data.match(jsonRegExp) || data.match(wordRegExp)) {
          this.setState({ [error]: 'Invalid input' });
          return false;
      }
      else return true;
    }

    handleSubmit = (event) => {
      var self = this;
      event.preventDefault();
      if (!this.missingRequired()) {
          if (this.validateContent(this.state.materialDesignation, 'materialDesignationError') &
              this.validateContent(this.state.supplierDesignation, 'supplierDesignationError') &
              this.validateContent(this.state.notes, 'notesError')) {
                  axios.post(settings.backend_url + '/materials',
                    {materialDesignation: this.state.materialDesignation,
                      pdiv: this.state.pdiv,
                      purchaser: this.state.purchaser,
                      supplier: this.state.supplier,
                      supplierDesignation: this.state.supplierDesignation,
                      originalManufacturer: this.state.originalManufacturer,
                      materialCategory: this.state.materialCategory,
                      materialType: this.state.materialType,
                      primaryMaterial: this.state.primaryMaterial,
                      metalAlloy: this.state.metalAlloy,
                      notes: this.state.notes,
                      materialStatus: 'Active'
                    })
                    .then(res => {
                      self.setState({
                        materialDesignation: '',
                        pdiv: null,
                        purchaser: null,
                        supplier: null,
                        materialCategory: null
                      });
                      self.refreshMaterials();
                      self.props.closeModal();
                    })
                    .catch(err => {
                      console.error(err);
                    });
              }
      }
    };

  setDefaultValue = (event, { name, value }) => {
      return "test"
  };  

  handleChange = (event, { name, value }) => {
      if(name === 'pdiv') {
        this.sortPurchasersAndVendorsByFactory(value)
        value = value.name;
        this.setState({ 
          [name]: value,
          ["Purchaser"]: null,
          ["Vendor"]: null
        });
      }
      this.setState({ [name]: value });
      const error = name + 'Error';
      this.setState({ [error]: false });
  };

  sortPurchasersAndVendorsByFactory = (pdiv) => {
    const { vendorOptions } = this.props;
    this.filteredPurchasers = this.attributes.purchaserOptions.filter(purchaserOption => purchaserOption.pdiv === pdiv.id);
    this.filteredVendors = vendorOptions.filter(vendorOption => vendorOption.pdiv === pdiv.name);
    this.filteredVendors.sort((a, b) => a.text.localeCompare(b.text)); 
  };

    render() {
        return (
          <Modal
            open={this.props.isOpen}
            onClose={this.props.closeModal}>
            <Modal.Header>CREATE MATERIAL</Modal.Header>

            <Modal.Content>
              <Form>
                <Header as ='h4'>INTERNAL INFORMATION</Header>
                <Form.Group>
                    <Form.Input fluid required width='8' style={{height: '2.7rem'}} label='SKF Material Designation' name='materialDesignation' placeholder='Enter SKF Designation'
                                onChange={this.handleChange} error={this.state.materialDesignationError} />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Select fluid required search label='Factory' name='pdiv' options={this.attributes.pdivOptions}
                                placeholder='Start typing to see possible matches' 
                                onChange={this.handleChange} error={this.state.pdivError}/>
                    <Form.Select fluid required search label='Purchaser' name='purchaser' options={this.filteredPurchasers}
                                placeholder='Start typing to see possible matches'
                                onChange={this.handleChange} error={this.state.purchaserError} />
                </Form.Group>

                <Header as ='h4'>VENDOR INFORMATION</Header>
                <Form.Group widths='equal'>
                    <Form.Select fluid required search label='Vendor' name='supplier' options={this.filteredVendors}
                                placeholder='Start typing to see possible matches'
                                onChange={this.handleChange} error={this.state.supplierError}/>
                    <Form.Input fluid style={{height: '2.7rem'}} label='Vendor Material Designation' name='supplierDesignation' placeholder='Enter Supplier Designation'
                                onChange={this.handleChange} />
                </Form.Group>
                <Form.Group>
                    <Form.Input fluid width='8' style={{height: '2.7rem'}} label='Original Manufacturer' name='originalManufacturer' placeholder='Enter Original Manufacturer'
                                onChange={this.handleChange} />
                </Form.Group>

                <Header as ='h4'>MATERIAL INFORMATION</Header>
                <Form.Group widths='equal'>
                    <Form.Select fluid required search label='Category' name='materialCategory' options={this.attributes.materialCategoryOptions}
                                placeholder='Start typing or choose from list'
                                onChange={this.handleChange} error={this.state.categoryError} />
                    <Form.Select fluid search label='Type' name='materialType' options={this.attributes.materialTypeOptions}
                                placeholder='Start typing or choose from list'
                                onChange={this.handleChange} />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Select fluid search label='Primary Material' name='primaryMaterial' options={this.attributes.primaryMaterialOptions} 
                                placeholder='Start typing or choose from list'
                                onChange={this.handleChange} />
                    <Form.Select fluid search label='Metal Alloy' name='metalAlloy' options={this.attributes.metalAlloyOptions}
                                placeholder='Start typing or choose from list'
                                onChange={this.handleChange} />
                </Form.Group>

                <Header as ='h4'>NOTES</Header>
                <Form.TextArea style={{ fontFamily: 'SKF-Chevin' }} label='Material Notes' name='notes'
                                   placeholder='Enter notes about the material'
                                   onChange={this.handleChange} />                
                </Form>
            </Modal.Content>

            <Modal.Actions>
              <Button basic
                onClick={this.props.closeModal}
                content='Cancel'
              />
              <Button
                content='Create'
                type='submit'
                onClick={this.handleSubmit}
                disabled={this.missingRequired()}
              />
            </Modal.Actions>
            

          </Modal>
        );
    }
}

export default CreateMaterialModal;