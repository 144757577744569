import React, {Component} from 'react';
import axios from 'axios';
import { Button, Confirm, Form, Header, Table } from 'semantic-ui-react'
import _ from 'lodash'
import * as jwtDecode from "jwt-decode";
import Vendor from './vendor/Vendor';

import * as settings from '../settings';

class Vendors extends Component {
    constructor(props) {
        super(props);

        this.attributes = props.attributes;
        this.goToMaterials = props.goToMaterials;

        this.readOnly = false;

        this.accessToken = localStorage.getItem('vcr-accessToken');
        var sessionIdInfo = jwtDecode(this.accessToken);
        for(let i = 0; i < sessionIdInfo['cognito:groups'].length; i++) {
            if(sessionIdInfo['cognito:groups'][i] === 'vcr-read-only') {
                this.readOnly = true;
                break;
            }
        }

        this.state = {
            vendorsList: [],
            filteredList: [],
            view: this.renderTableHeaderOnly('Loading...'),
            sortedColumn: null,
            sortDirection: null,
            filters: null,
            filterOptions: {
                nameOptions: [
                    {key: 'NAME', text: 'NAME', value: 'NAME'},
                ],
                locationOptions: [
                    {key: 'LOCATION', text: 'LOCATION', value: 'LOCATION'},
                ],
                internalOptions: [
                    {key: 'INTERNAL', text: 'INTERNAL', value: 'INTERNAL'}
                ],
                pdivOptions: [
                    {key: 'FACTORY', text: 'FACTORY', value: 'FACTORY'}
                ]
            },
            selectedFilters: {vendorName: null, vendorLocation: null, internalVendor: null, pdiv: null},
            confirmHeader: '',
            deleteConfirmOpen: false,
            deleteData: null
        };
    }

    componentDidMount() {
        this.getVendorsList();
    }

    getVendorsList = () => {
        let accessToken = localStorage.getItem('vcr-accessToken');
        axios.get(settings.backend_url + '/vendors',
            {
                headers: {
                    Authorization: accessToken
                }
            })
            .then(res => {
                const vendorsList = res.data.data.Items;
                if(!vendorsList || vendorsList.length < 1) {
                    this.setState({view: this.renderTableHeaderOnly('No data found')});
                } else {
                    var sessionIdInfo = jwtDecode(accessToken);
                    let adminUser = false;
                    for(let i = 0; i < sessionIdInfo['cognito:groups'].length; i++) {
                        if(sessionIdInfo['cognito:groups'][i] === 'vcr-admin') {
                            adminUser = true;
                            break;
                        }
                    }
                    if(!adminUser) {
                        let factory = JSON.parse(localStorage.getItem('vcr-factory'));
                        if(factory) {
                            let pdivName = factory.key;
                            if(pdivName) {
                                for(let i = 0; i < vendorsList.length; i++) {
                                    if(vendorsList[i].pdiv !== pdivName) {
                                        vendorsList.splice(i, 1);
                                        i--;
                                    }
                                }
                            }
                        } else {
                            vendorsList.splice(0, vendorsList.length);
                        }
                    }
                    this.setState({vendorsList: vendorsList}, function() {
                        this.populateFilterOptions();
                        this.applyFilters(true);
                    });
                }
            })
            .catch(err => {
                this.setState({view: this.renderTableHeaderOnly('Error retrieving data')});
                console.error(err);
            });
    }

    populateFilterOptions() {
        let uniqueNames = [...new Set(this.state.vendorsList.map(x => x.vendorName))];
        let uniqueLocations = [...new Set(this.state.vendorsList.map(x => x.vendorLocation))];
        let uniqueInternals = [...new Set(this.state.vendorsList.map(x => x.internalVendor))];
        let uniquePdivs = [...new Set(this.state.vendorsList.map(x => x.pdiv))];

        uniqueNames = _.sortBy(uniqueNames, function (name) {
            return name.toLowerCase();
        });
        uniqueLocations = _.sortBy(uniqueLocations, function (name) {
            return name.toLowerCase();
        });
        uniqueInternals = _.sortBy(uniqueInternals, function (name) {
            return name.toLowerCase();
        });
        uniquePdivs = _.sortBy(uniquePdivs, function (name) {
            return name.toLowerCase();
        });

        var self = this;
        self.state.filterOptions.nameOptions.splice(1, self.state.filterOptions.nameOptions.length - 1);
        self.state.filterOptions.locationOptions.splice(1, self.state.filterOptions.locationOptions.length - 1);
        self.state.filterOptions.internalOptions.splice(1, self.state.filterOptions.internalOptions.length - 1);
        self.state.filterOptions.pdivOptions.splice(1, self.state.filterOptions.pdivOptions.length - 1);
        _.each(uniqueNames, function (item, index) {
            self.state.filterOptions.nameOptions.push({key: item, text: item, value: item});
        });
        _.each(uniqueLocations, function (item, index) {
            self.state.filterOptions.locationOptions.push({key: item, text: item, value: item});
        });
        _.each(uniqueInternals, function (item, index) {
            self.state.filterOptions.internalOptions.push({key: item, text: item, value: item});
        });
        _.each(uniquePdivs, function (item, index) {
            self.state.filterOptions.pdivOptions.push({key: item, text: item, value: item});
        });
    }

    changeView = (view) => {
        if(view === null) {
            if(this.state.vendorsList.length < 1) {
                this.setState({view: this.renderTableHeaderOnly('No data found')});
            } else {
                this.setState({view: this.renderTable()});
            }
        } else {
            this.setState({view: view});
        }
    }

    updateVendor = (vendor) => {
        for(let i = 0; i < this.state.vendorsList.length; i++) {
            if(this.state.vendorsList[i].id === vendor.id) {
                let vendorsList = this.state.vendorsList;
                vendorsList[i] = vendor;
                this.setState({vendorsList: vendorsList}, function() {
                    this.populateFilterOptions();
                    this.applyFilters(false);
                });
            }
        }
    }

    showFilters = () => {
        if(!this.state.filters) {
            this.setState({filters: (<div className="filter-box">
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Select fluid name='name' options={this.state.filterOptions.nameOptions}
                                    defaultValue={this.state.filterOptions.nameOptions[0].value}
                                    onChange={this.handleChange} />
                        <Form.Select fluid name='location' options={this.state.filterOptions.locationOptions}
                                    defaultValue={this.state.filterOptions.locationOptions[0].value}
                                    onChange={this.handleChange} />
                        <Form.Select fluid name='internal' options={this.state.filterOptions.internalOptions}
                                    defaultValue={this.state.filterOptions.internalOptions[0].value}
                                    onChange={this.handleChange} />
                        <Form.Select fluid name='pdiv' options={this.state.filterOptions.pdivOptions}
                                    defaultValue={this.state.filterOptions.pdivOptions[0].value}
                                    onChange={this.handleChange} />
                    </Form.Group>
                </Form>
            </div>)}, function() {
                this.changeView(null);
            });
        } else {
            this.setState({filters: null}, function() {
                this.changeView(null);
            });
        }
    };

    applyFilters = (reload) => {
        let filteredList = [];
        for(let i = 0; i < this.state.vendorsList.length; i++) {
            if(this.state.selectedFilters.vendorName !== null) {
                if(this.state.vendorsList[i].vendorName !== this.state.selectedFilters.vendorName) {
                    continue;
                }
            }
            if(this.state.selectedFilters.vendorLocation !== null) {
                if(this.state.vendorsList[i].vendorLocation !== this.state.selectedFilters.vendorLocation) {
                    continue;
                }
            }
            if(this.state.selectedFilters.internalVendor !== null) {
                if(this.state.vendorsList[i].internalVendor !== this.state.selectedFilters.internalVendor) {
                    continue;
                }
            }
            if(this.state.selectedFilters.pdiv !== null) {
                if(this.state.vendorsList[i].pdiv !== this.state.selectedFilters.pdiv) {
                    continue;
                }
            }
            filteredList.push(this.state.vendorsList[i]);
        }
        this.setState({filteredList: filteredList}, function() {
            if(reload) {
                this.changeView(null);
            }
        });
    }

    handleChange = (event, { name, value }) => {
        if(name === 'name') {
            name = 'vendorName';
        }
        if(name === 'location') {
            name = 'vendorLocation';
        }
        if(name === 'internal') {
            name = 'internalVendor';
        }
        if(value.toLowerCase() === 'name') {
            value = 'vendorName';
        }
        if(value.toLowerCase() === 'location') {
            value = 'vendorLocation';
        }
        if(value.toLowerCase() === 'internal') {
            value = 'internalVendor';
        }
        if(value.toLowerCase() === 'factory') {
            value = 'pdiv';
        }

        if(value !== name) {
            let filters = this.state.selectedFilters;
            filters[name] = value;
            this.setState({selectedFilters: filters}, function() {
                this.applyFilters(true);
            });
        } else {
            let filters = this.state.selectedFilters;
            filters[name] = null;
            this.setState({selectedFilters: filters}, function() {
                this.applyFilters(true);
            });
        }
    };

    handleSort = (clickedColumn) => () => {
        if (this.state.sortedColumn !== clickedColumn) {
            this.setState({
                sortedColumn: clickedColumn,
                filteredList: _.sortBy(this.state.filteredList, function (vendor) {
                    return vendor[clickedColumn].toLowerCase();
                }),
                sortDirection: 'ascending',
            });
        } else {    
            this.setState({
                filteredList: this.state.filteredList.reverse(),
                sortDirection: this.state.sortDirection === 'ascending' ? 'descending' : 'ascending',
            });
        }
        this.changeView(null);
    }

    deleteVendor = (vendorId) => {
        axios.delete(settings.backend_url + '/vendors/' + vendorId)
            .then(res => {
                let vendorsList = this.state.vendorsList;
                for(let i = 0; i < vendorsList.length; i++) {
                    if(vendorsList[i].id === vendorId) {
                        vendorsList.splice(i, 1);
                        this.setState({vendorsList: vendorsList}, function() {
                            this.applyFilters(true);
                        });
                    }
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    cancelDelete = () => {
        this.setState({ deleteConfirmOpen: false }, function() {
            this.changeView(null);
        });
    }

    confirmDelete = () => {
        this.deleteVendor(this.state.deleteData);
        this.setState({ deleteData: null, deleteConfirmOpen: false }, function() {
            this.changeView(null);
        });
    }

    openConfirmDialog = (e, vendor) => {
        if(!this.readOnly) {
            let headerText = 'Delete vendor ' + vendor.vendorName + '?';
            e.preventDefault();
            e.stopPropagation();
            this.setState({confirmHeader: headerText , deleteData: vendor.id, deleteConfirmOpen: true}, function() {
                this.changeView(null);
            });
        }
    }

    renderTableHeaderOnly(noData) {
        return (
            <div>
                <Header as='h1'>VENDORS</Header>
                <div className="filter-grid">
                    <Button
                        content='SHOW FILTERS'
                        type='submit'
                        floated='right'
                    />
                </div>
                <Table sortable singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Location</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Internal</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '20px'}}></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                </Table>
                <div className="no-data">
                    {noData}
                </div>
            </div>
        );
    }

    renderTable() {
        return (
            <div>
                <Header as='h1'>VENDORS</Header>
                <div className="filter-grid">
                    <Button
                        content='SHOW FILTERS'
                        type='submit'
                        onClick={this.showFilters}
                        floated='right'
                    />
                </div>
                <div>
                    {this.state.filters}
                </div>
                <Table sortable singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={this.state.sortedColumn === 'vendorName' ? this.state.sortDirection : null}
                                onClick={this.handleSort('vendorName')}
                            >
                                Name
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.state.sortedColumn === 'vendorLocation' ? this.state.sortDirection : null}
                                onClick={this.handleSort('vendorLocation')}
                            >
                                Location
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.state.sortedColumn === 'description' ? this.state.sortDirection : null}
                                onClick={this.handleSort('description')}
                            >
                                Description
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.state.sortedColumn === 'internalVendor' ? this.state.sortDirection : null}
                                onClick={this.handleSort('internalVendor')}
                            >
                                Internal
                            </Table.HeaderCell>
                            <Table.HeaderCell style={{width: '20px'}}>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
            
                    <Table.Body>
                        {this.state.filteredList.map((vendor) => {
                            return <Table.Row className="cursor-pointer" onClick={() => this.setState({view: <Vendor readOnly={this.readOnly} goToMaterials={this.goToMaterials} changeView={this.changeView} updateVendor={this.updateVendor} vendor={vendor}/>})}>
                            <Table.Cell>{vendor.vendorName}</Table.Cell>
                            <Table.Cell>{vendor.vendorLocation}</Table.Cell>
                            <Table.Cell>{vendor.description}</Table.Cell>
                            <Table.Cell>{vendor.internalVendor}</Table.Cell>
                            <Table.Cell onClick={(e) => this.openConfirmDialog(e, vendor)}><img src="images/bin-1.png" alt='left arrow'/></Table.Cell>
                            </Table.Row>
                        })}
                    </Table.Body>
                </Table>
                <Confirm
                    open={this.state.deleteConfirmOpen}
                    header={this.state.confirmHeader}
                    onCancel={this.cancelDelete}
                    onConfirm={this.confirmDelete}
                />
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.state.view}
            </div>
        );
    }
}

export default Vendors;
